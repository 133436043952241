import VueRouter from 'vue-router';

// import {router} from './main'
// VueRouter

const Home = () => import('./pages/Home.vue');
const Programs = () => import('./pages/Programs.vue')
const Gallery  = () => import( './pages/Gallery.vue')
const Contact = () => import( './pages/Contact.vue')
const About = () => import('./pages/About.vue')
// const Navigation = () => import('./pages/Navigation')


 const routes =  [
    { path: '*', component: Home },
    { path: '/shows', component: Programs },
    { path: '/gallery', component: Gallery },
    { path: '/contact', component: Contact },
    { path: '/aboutus', component: About},
    // { path: '/nav', component: Navigation },

]

export const router = new VueRouter({
    scrollBehavior (to, from, savedPosition) {  
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    mode:'history',
    routes,
  })
  
 