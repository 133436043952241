<template>
<v-app>
  <div id="app">
    <div :id="langFont">


    <v-fab-transition>
     <v-btn
       x-large
       color="primary"
       :key="audio"
       elevation="2"
       right fixed
       fab bottom
       @click="playSound">
       <v-icon v-if="audio">mdi-play</v-icon>
       <v-icon v-if="!audio">mdi-pause</v-icon>
       </v-btn>
    </v-fab-transition>
    <v-row>
      <v-col lg="12" xl="12" md="11" sm="11">
       <Header/>
      </v-col>
      <v-col cols="2" md="1" sm="1" class="hidden-lg-and-up">
        <MenuBtn/>
      </v-col>
    </v-row>
    <router-view></router-view>
    <Footer/>


        </div>
  </div>
</v-app>
</template>

<script>
// import i18n from './i18n';
import MenuBtn from './components/MenuBtn.vue';
import store from './store/index'
const Header = () => import('./components/Header.vue');
const Footer = () => import('./components/Footer.vue');

export default {
  components: {
    Header,
    Footer,
    MenuBtn,
  },
  data:()=>({
    audio:true,
    langfont:'en-font'
  }),
  methods:{
      playSound(){
         store.dispatch('playSound')
         if(!store.state.stream.paused){
           this.audio = false
           this.$gtag.event('Play', {
            'event_category': 'Stream',
            'event_label': 'Play Radio'
           })
           
           }else{
             this.audio = true
             this.$gtag.event('Pause/Stop', {
               'event_category': 'Stream',
               'event_label': 'Pause/Stop Radio'
             })
         }
       }
  },
  computed:{
    langFont(){
      return store.state.font
    }
      },
       metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Maryud103FM مريود',
      meta: [{
       vmid: 'description',
       name: 'description',
       content: 'A private Sudanese radio station operating from Khartoum, Sudan محطة اذاعة سودانية تبث من الخرطوم السودان',
     }]
    }
  // }
}
</script>
<style lang="scss">
@import './scss/app.scss';
  
</style>