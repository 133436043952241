import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'

Vue.use(Vuex)
 
let sound = 'https://almaruod-adil.radioca.st/stream'
// let sound = '88.198.70.25'


export default new Vuex.Store({
  state: {
    stream: new Audio(sound),
    font: 'en-font'
  },
  mutations: {},
  actions: {
    playSound () {
      //  let audio = new Audio(this.stream);
       if(this.state.stream.paused){
          this.state.stream.load()
          this.state.stream.play()
         }else if(!this.state.stream.paused){ 
          this.state.stream.pause();
       }
    },
    toggleFont(){
      if (i18n.locale == 'en') {
        this.state.font ='en-font';
       } else  {        
        this.state.font = 'ar-font';
      }
    }
  },
})
