import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify' // path to vuetify export
import { router } from './router.js'
import LottieAnimation from 'lottie-web-vue'
import i18n from './i18n'
import VueGtag from "vue-gtag";
import VueKinesis from 'vue-kinesis'
import store from './store'
import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

Vue.use(VueKinesis)

Vue.use(VueGtag, {
  config: { id: "G-KYCEDP1J0H" },
  appName: 'Maryud103FM',
  pageTrackerScreenviewEnabled: true
}, router)

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.use(VueRouter)





new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App)
}).$mount('#app');
