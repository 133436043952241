<template>
    <v-main>
         <v-app-bar-nav-icon color="primary" @click.stop="drawer = !drawer" class="hidden-lg-and-up"></v-app-bar-nav-icon>

         <div class="mx-auto justify-center hidden-lg-and-up">
                <v-navigation-drawer
                color="#404040"
                   v-model="drawer"
                    fixed
                   temporary width="100%"
                   right
                   app
                   
                 >
                   <v-list three-line
                     nav
                     dense
                     class="mx-auto"
                     height="100%"
                   >
                     <v-list-item-group
                         v-model="group"
                         active-class="primary--text"
                       >
                         <v-list-item class="text-center white--text" to="/home">
                           <v-list-item-title>
                                   {{$t('home')}}
                              
                           </v-list-item-title>
                         </v-list-item>
               
                         <v-list-item to='/aboutus' class="text-center white--text">
                           <v-list-item-title >
                            
                               {{$t('about')}}
                               </v-list-item-title>
                         </v-list-item>
               
                         <v-list-item to='/contact' class="text-center white--text">
                           <v-list-item-title>
                                {{$t('contact')}}
                                </v-list-item-title>
                         </v-list-item>
                         <v-list-item to="/shows" class="text-center white--text">
                           <v-list-item-title>
                                   {{$t('shows')}}
                           </v-list-item-title>
                         </v-list-item>
                         <v-list-item class="text-center white--text" to="/gallery ">
                           <v-list-item-title>
                                   {{$t('gallery')}}
                           </v-list-item-title>
                         </v-list-item>
                       </v-list-item-group>
                     </v-list>
               </v-navigation-drawer>
                </div>

    </v-main>
</template>
<script>
export default {
    name:'MenuBtn',
    data:()=>({
        drawer:false,
        group:null
    }),
     watch: {
      group () {
        this.drawer = false
      },
    },
}
</script>
